import {
  map, assoc, propOr, prop, isEmpty, pipe, props, join, filter, find, includes, length,
  pluck, sum, reject, propEq, split, head, contains, values, reverse, mapObjIndexed, flatten
} from 'ramda'
import { createSelector } from 'reselect'
import {
  findById,
  setOrder,
  setShipment,
  setShipmentItems,
  setProduct,
  setProductStockAdjustment,
  setSupplier,
  setDelivery,
  setDeliveryProduct,
  setRestock,
} from './helpers'

// ****
// General selectors
export const activeCouriersSelector = createSelector(
  state => state.app.couriers,
  (couriers) =>
    !isEmpty(couriers)
    ? reject(propEq('active', false), couriers)
    : []
)

// ****
// Dashboard selectors
export const ordersReadyToShipSelector = createSelector(
  state => state.dashboard.readyToShip,
  state => state.app.couriers,
  (orders, couriers) => {
    const packfleet = findById('packfleet', couriers)
    const packfleetCoverage = propOr([], 'coverage_postcodes', packfleet)

    let cases = {
      12: {
        name: '12 case',
        total: 0,
        packfleet: 0,
        other: 0
      },
      9: {
        name: '9 case',
        total: 0,
        packfleet: 0,
        other: 0
      },
      6: {
        name: '6 case',
        total: 0,
        packfleet: 0,
        other: 0
      },
      4: {
        name: '4 case',
        total: 0,
        packfleet: 0,
        other: 0
      },
      3: {
        name: '3 case',
        total: 0,
        packfleet: 0,
        other: 0
      },
      2: {
        name: '2 case',
        total: 0,
        packfleet: 0,
        other: 0
      },
      1: {
        name: '1 case',
        total: 0,
        packfleet: 0,
        other: 0
      }
    }

    map(order => {
      const postcodeArea = pipe(split(' '), head)(order.customer_delivery_postcode || '')
      const items = pipe(
        map(pipe(
          props(['notified', 'allocated']),
          sum,
        )),
        sum
      )(order.order_items)

      let number = items
      while (number > 0) {
        if (number > 9) {
        // if (number > 6) {
          cases[12]['total']++
          if (contains(postcodeArea, packfleetCoverage)) {
            cases[12]['packfleet']++
          } else {
            cases[12]['other']++
          }
          number -= Math.min(number, 12)
        } else if (number >= 7 && number <= 9) {
          if (contains(postcodeArea, packfleetCoverage)) {
            cases[12]['total']++
            cases[12]['packfleet']++
          } else {
            cases[9]['total']++
            cases[9]['other']++
          }
          number -= Math.min(number, 9)
        } else if (number >= 5 && number <= 6) {
          cases[6]['total']++
          if (contains(postcodeArea, packfleetCoverage)) {
            cases[6]['packfleet']++
          } else {
            cases[6]['other']++
          }
          number -= Math.min(number, 6)
        } else if (number === 4) {
          cases[4]['total']++
          if (contains(postcodeArea, packfleetCoverage)) {
            cases[4]['packfleet']++
          } else {
            cases[4]['other']++
          }
          number -= 4
        } else if (number === 3) {
          cases[3]['total']++
          if (contains(postcodeArea, packfleetCoverage)) {
            cases[3]['packfleet']++
          } else {
            cases[3]['other']++
          }
          number -= 3
        } else if (number === 2) {
          cases[2]['total']++
          if (contains(postcodeArea, packfleetCoverage)) {
            cases[2]['packfleet']++
          } else {
            cases[2]['other']++
          }
          number -= 2
        } else if (number === 1) {
          cases[1]['total']++
          if (contains(postcodeArea, packfleetCoverage)) {
            cases[1]['packfleet']++
          } else {
            cases[1]['other']++
          }
          number -= 1
        }
      }
    })(orders)

    return pipe(values, reverse)(cases)
  }
)

export const ordersDatedDeliverySelector = createSelector(
  state => state.dashboard.datedDelivery,
  state => state.app.orderStatuses,
  state => state.app.suppliers,
  (orders, statuses, suppliers) =>
    !isEmpty(orders)
    ? map(order => setOrder(order, statuses, suppliers), orders)
    : []
)

export const shipmentsActiveSelector = createSelector(
  state => state.dashboard.shipmentsActive,
  state => state.app.couriers,
  (shipments, couriers) =>
    !isEmpty(shipments)
    ? map(shipment => assoc('consignee',
      // TODO have to standartize setShipment, ship order response not the same
      pipe(
        prop('order'),
        props(['customer_delivery_first_name', 'customer_delivery_last_name']),
        join(' ')
      )(shipment)
    )(setShipment(shipment, couriers)), shipments)
    : []
)

// ****
// Order selectors
export const ordersSelector = createSelector(
  state => state.orders.data,
  state => state.app.orderStatuses,
  state => state.app.suppliers,
  (orders, statuses, suppliers) =>
    !isEmpty(orders)
    ? map(order => setOrder(order, statuses, suppliers), orders)
    : []
)

export const orderSelector = createSelector(
  state => state.order.data,
  state => state.app.orderStatuses,
  state => state.app.suppliers,
  (order, statuses, suppliers) =>
    !isEmpty(order)
    ? setOrder(order, statuses, suppliers)
    : {}
)

export const orderShipmentsSelector = createSelector(
  state => state.order.orderShipments,
  state => state.app.couriers,
  (orderShipments, couriers) =>
    !isEmpty(orderShipments)
    ? map(shipment => setShipment(shipment, couriers), orderShipments)
    : []
)

export const orderHistorySelector = createSelector(
  state => state.order.orderHistory,
  state => state.app.users,
  (orderHistory, users) =>
    !isEmpty(orderHistory)
    ? map(
      history => assoc(
        'created_by',
        propOr('', 'name', findById(prop('created_by_id', history), users)),
        history
      ),
      orderHistory
    )
    : []
)

// ****
// Shipments selectors
export const shipmentsCouriersServicesSelector = createSelector(
  state => state.shipments.filters,
  state => state.app.couriers,
  (filters, couriers) => {
    const filteredCouriers = pipe(
      find(propEq('columnName', 'courier')),
      propOr([], 'value')
    )(filters)

    const services = pipe(
      reject(courier => isEmpty(courier.services)),
      filter(courier => {
        if (!isEmpty(filteredCouriers)) {
          return includes(courier.name, filteredCouriers)
        }
        return courier
      }),
      map(courier => {
        let prefix = ''
        if (isEmpty(filteredCouriers) || length(filteredCouriers) > 1) {
          prefix = `${courier.name}: `
        }
        let services = []
        mapObjIndexed((service, key) => {
          services.push({
            label: prefix + service,
            value: key
          })
        }, courier.services)

        return {
          ...courier,
          services: services
        }
      }),
      pluck('services'),
      flatten
    )(couriers)

    return services
  }
)

export const shipmentsSelector = createSelector(
  state => state.shipments.data,
  state => state.app.couriers,
  (shipments, couriers) =>
    !isEmpty(shipments)
    ? map(shipment => assoc('consignee',
      // TODO have to standartize setShipment, ship order response not the same
      pipe(
        prop('order'),
        props(['customer_delivery_first_name', 'customer_delivery_last_name']),
        join(' ')
      )(shipment)
    )(setShipment(shipment, couriers)), shipments)
    : []
)

export const shipmentSelector = createSelector(
  state => state.shipment.data,
  state => state.app.couriers,
  (shipment, couriers) =>
    !isEmpty(shipment)
    ? pipe(
      assoc('courier', propOr('', 'name', findById(shipment.courier, couriers))),
      assoc('courier_id', propOr('', 'id', findById(shipment.courier, couriers)))
    )(shipment)
    : {}
)

export const shipmentProductsSelector = createSelector(
  state => state.shipment.shipmentProducts,
  state => state.app.suppliers,
  (shipmentProducts, suppliers) =>
    !isEmpty(shipmentProducts)
    ? map(product => setProduct(product, suppliers), shipmentProducts)
    : []
)

export const shipmentItemsSelector = createSelector(
  state => state.shipment.shipmentItems,
  state => state.shipment.data.courier,
  (shipmentItems, courier) =>
    !isEmpty(shipmentItems)
    ? setShipmentItems(shipmentItems, courier)
    : []
)

// ****
// Products selectors
export const productsSelector = createSelector(
  state => state.products.data,
  state => state.app.suppliers,
  (products, suppliers) =>
    !isEmpty(products)
    ? map(product => setProduct(product, suppliers), products)
    : []
)

export const productSelector = createSelector(
  state => state.product.data,
  state => state.app.suppliers,
  (product, suppliers) =>
    !isEmpty(product)
    ? setProduct(product, suppliers)
    : {}
)

export const productStockAdjustmentsSelector = createSelector(
  state => state.product.stockAdjustments,
  state => state.app.suppliers,
  state => state.app.users,
  (adjustments, suppliers, users) =>
    !isEmpty(adjustments)
    ? map(adjustment => setProductStockAdjustment(adjustment, suppliers, users), adjustments)
    : []
)

// ****
// Suppliers selectors
export const suppliersSelector = createSelector(
  state => state.app.suppliers,
  (suppliers) =>
    !isEmpty(suppliers)
    ? map(setSupplier, suppliers)
    : []
)

export const supplierSelector = createSelector(
  state => state.app.suppliers,
  state => state.suppliers.supplierId,
  (suppliers, supplierId) => {
    const supplier = findById(supplierId, suppliers)
    return (
      !isEmpty(supplier)
      ? setSupplier(supplier)
      : {}
    )
  }
)

// ****
// Deliveries selectors
export const deliveriesSelector = createSelector(
  state => state.deliveries.data,
  state => state.app.suppliers,
  state => state.app.users,
  (deliveries, suppliers, users) =>
    !isEmpty(deliveries)
    ? map(delivery => setDelivery(delivery, suppliers, users), deliveries)
    : []
)

export const deliveryRestockProductsSelector = createSelector(
  state => state.delivery.restockProducts,
  state => state.delivery.data,
  (products, delivery) =>
    !isEmpty(products)
    ? map(product => setDeliveryProduct(product, prop('id', delivery)), products)
    : []
)

export const deliveryOtherProductsSelector = createSelector(
  state => state.delivery.otherProducts,
  state => state.delivery.data,
  (products, delivery) =>
    !isEmpty(products)
    ? map(product => setDeliveryProduct(product, prop('id', delivery)), products)
    : []
)

// ****
// Restock selectors
export const restockSelector = createSelector(
  state => state.restock.data,
  state => state.app.suppliers,
  (restock, suppliers) =>
    !isEmpty(restock)
    ? map(supplier => setRestock(supplier, suppliers), restock)
    : []
)
