import {
  pipe, prop, map, sum, length, join,
} from 'ramda'
import { Types } from '../actions'
import Immutable from 'seamless-immutable'
import { createReducer } from 'reduxsauce'

const INITIAL_STATE = Immutable({
  // Grid
  uiLoadingGet: false,
  data: [],
  columns: [
    { name: 'delivered', title: 'Delivered', getCellValue: row => row.delivered_at ? 'Yes' : 'No' },
    { name: 'shipment_reference', title: 'Reference' },
    { name: 'courier_reference', title: 'Courier Ref.' },
    { name: 'consignee', title: 'Consignee' },
    { name: 'created_at', title: 'Date Added' },
    { name: 'dispatched_at', title: 'Date Dispatched' },
    { name: 'delivered_at', title: 'Date Delivered' },
    { name: 'courier', title: 'Courier' },
    { name: 'service', title: 'Service', getCellValue: row => row.service_name },
    {
      name: 'parcels',
      title: 'Parcels',
      getCellValue: row => {
        const parcels = pipe(prop('shipment_items'), length)(row)
        const weight = pipe(prop('shipment_items'), map(prop('weight')), sum)(row)
        return `${parcels} (${weight}kg)`
      },
    },
    {
      name: 'tracking',
      title: 'Tracking',
      getCellValue: row => join('\n', row.tracking)
    },
    { name: 'action', title: 'View', getCellValue: row => row.id },
  ],
  columnExtensions: [
    { columnName: 'delivered', width: 110 },
    { columnName: 'dispatched_at', width: 130 },
    { columnName: 'delivered_at', width: 130 },
    { columnName: 'action', width: 70, align: 'right' },
  ],
  visibilityColumnExtensions: [
    { columnName: 'delivered', togglingEnabled: false },
    { columnName: 'shipment_reference', togglingEnabled: false },
    { columnName: 'action', togglingEnabled: false },
  ],
  hiddenColumnNames: [
    'courier_reference', 'dispatched_at', 'delivered_at',
  ],
  sorting: [
    { columnName: 'created_at', direction: 'desc' },
  ],
  filters: [],
  currentPage: 0,
  totalCount: 0,
  pageSizes: [10, 25, 50],
  pageSize: 10,
})

// GET Shipments
const watchShipmentsFilter = (state, { params }) => {
  return state.merge({
    ...params
  })
}

const getShipmentsAttempt = (state, { params }) => {
  return state.merge({
    uiLoadingGet: true,
    ...params
  })
}

const getShipmentsSuccess = (state, { data }) => {
  return state.merge({
    uiLoadingGet: false,
    ...data
  })
}

const getShipmentsFailure = state => {
  return state.merge({
    uiLoadingGet: false,
  })
}

const setShipmentsGridState = (state, { name, value }) => {
  return state.set(name, value)
}

const logout = state => INITIAL_STATE

// map our types to our handlers
const ACTION_HANDLERS = {
  // GET Shipments
  [Types.WATCH_SHIPMENTS_FILTER]: watchShipmentsFilter,
  [Types.GET_SHIPMENTS_ATTEMPT]: getShipmentsAttempt,
  [Types.GET_SHIPMENTS_SUCCESS]: getShipmentsSuccess,
  [Types.GET_SHIPMENTS_FAILURE]: getShipmentsFailure,
  [Types.SET_SHIPMENTS_GRID_STATE]: setShipmentsGridState,
  // Reset
  [Types.LOGOUT]: logout,
}

export default createReducer(INITIAL_STATE, ACTION_HANDLERS)
