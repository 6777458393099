import React from 'react'
import PropTypes from 'prop-types'

import {
  length, mapObjIndexed
} from 'ramda'

// Material UI
import { withStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import MenuItem from '@material-ui/core/MenuItem'

const styles = theme => ({
  field: {
    margin: theme.spacing()
  }
})

const GridFilterSelectField = ({ classes, options, filter, onFilter }) => {
  let items = []
  mapObjIndexed((option, index) => {
    if (typeof option === 'string') {
      items.push({
        label: option,
        value: option
      })
    } else {
      items.push(option)
    }
  }, options)

  return (
    <TextField
      className={classes.field}
      fullWidth
      select
      SelectProps={{
        multiple: true
      }}
      value={filter ? filter.value : []}
      onChange={e => onFilter(
        length(e.target.value)
        ? { value: e.target.value }
        : null
      )}
    >
      {items.map((item, key) => (
        <MenuItem key={key} value={item.value}>
          {item.label}
        </MenuItem>
      ))}
    </TextField>
  )
}

GridFilterSelectField.propTypes = {
  options: PropTypes.array.isRequired,
  filter: PropTypes.shape({
    value: PropTypes.array.isRequired,
  }),
  onFilter: PropTypes.func.isRequired,
}

export default withStyles(styles)(GridFilterSelectField)
